import { Card, CardContent, Skeleton } from "@mui/material";

const EpisodeCardSkeleton = () => {
  return (
    <Card>
      <CardContent sx={{ margin: "0.4em" }}>
        <Skeleton variant="rectangular" height="250px" width="100%"></Skeleton>
        <Skeleton variant="text" width="90%"></Skeleton>
        <Skeleton variant="text" width="30%"></Skeleton>
        <Skeleton variant="text" width="20%"></Skeleton>
      </CardContent>
    </Card>
  );
};

export default EpisodeCardSkeleton;
