import { createContext, useContext, useEffect, useState } from "react";
import { PaginatedEpisodes } from "../types/PaginatedEpisodes";

interface EpisodeFilters {
  nameFilter: string;
  episodeNumberFilter: string | null;
  releaseYearFromFilter: Date | null | undefined;
  releaseYearToFilter: Date | null | undefined;
  tagsFilter: string[];
}

export interface EpisodeContextType {
  episodes: PaginatedEpisodes;
  isLoading: boolean;
  filters: EpisodeFilters;
  orderBy: string;
  order: "asc" | "desc";
  page: number;
  pageSize: number;
  setOrderBy(field: string): void;
  setOrder(field: "asc" | "desc"): void;
  setPage(page: number): void;
  setPageSize(pageSize: number): void;
  setNameFilter(name: string): void;
  setEpisodeNumberFilter(episodeNumber: string): void;
  setReleaseYearFromFilter(releaseYearFrom: Date | null): void;
  setReleaseYearToFilter(releaseYearTo: Date | null): void;
  setTagsFilter(tags: string[]): void;
  resetFilters(): void;
}

interface EpisodeProviderProps {
  children: React.ReactNode;
}

const EpisodeContext = createContext<EpisodeContextType>(
  {} as EpisodeContextType
);

export function EpisodeProvider({ children }: EpisodeProviderProps) {
  const [episodes, setEpisodes] = useState<PaginatedEpisodes>(
    {} as PaginatedEpisodes
  );
  const [isLoading, setIsLoading] = useState(true);
  const [orderBy, setOrderBy] = useState<string>("episodeNumber");
  const [order, setOrder] = useState<"asc" | "desc">("asc");
  const [page, setPage] = useState<number>(0);
  const [pageSize, setPageSize] = useState<number>(10);

  const [nameFilter, setNameFilter] = useState("");
  const [episodeNumberFilter, setEpisodeNumberFilter] = useState<string | null>(
    ""
  );
  const [releaseYearFromFilter, setReleaseYearFromFilter] =
    useState<Date | null>(null);
  const [releaseYearToFilter, setReleaseYearToFilter] = useState<Date | null>(
    null
  );
  const [tagsFilter, setTagsFilter] = useState<string[]>([]);
  const filters = {
    nameFilter,
    episodeNumberFilter,
    releaseYearFromFilter,
    releaseYearToFilter,
    tagsFilter,
  };

  function getEpisodesPage() {
    let url = `${process.env.REACT_APP_API_URL}/v1/episode?page=${page}&pageSize=${pageSize}&order=${order}&orderBy=${orderBy}`;
    if (nameFilter) {
      url += `&nameFilter=${nameFilter}`;
    }
    if (episodeNumberFilter) {
      url += `&episodeNumberFilter=${episodeNumberFilter}`;
    }
    if (releaseYearFromFilter) {
      url += `&releaseYearFrom=${releaseYearFromFilter.getFullYear()}`;
    }
    if (releaseYearToFilter) {
      url += `&releaseYearTo=${releaseYearToFilter.getFullYear()}`;
    }
    if (tagsFilter.length > 0) {
      url += `&tags=${tagsFilter.join(",")}`;
    }

    setIsLoading(true);
    fetch(url)
      .then((response) => response.json())
      .then((json: PaginatedEpisodes) => {
        setIsLoading(false);
        setEpisodes(json);
      })
      .catch((err) => {
        console.log(err);
      });
  }

  function resetFilters() {
    setNameFilter("");
    setEpisodeNumberFilter("");
    setReleaseYearFromFilter(null);
    setReleaseYearToFilter(null);
    setTagsFilter([]);
  }

  useEffect(() => {
    const debounceFn = setTimeout(() => {
      setPage(0);
      getEpisodesPage();
    }, 250);

    return () => {
      clearTimeout(debounceFn);
    };
  }, [
    nameFilter,
    episodeNumberFilter,
    releaseYearFromFilter,
    releaseYearToFilter,
    tagsFilter,
  ]);

  useEffect(() => {
    getEpisodesPage();
  }, [page, pageSize, order, orderBy]);

  return (
    <EpisodeContext.Provider
      value={{
        episodes,
        isLoading,
        filters,
        orderBy,
        order,
        page,
        pageSize,
        setOrderBy,
        setOrder,
        setPage,
        setPageSize,
        setNameFilter,
        setEpisodeNumberFilter,
        setReleaseYearFromFilter,
        setReleaseYearToFilter,
        setTagsFilter,
        resetFilters,
      }}
    >
      {children}
    </EpisodeContext.Provider>
  );
}

export const useEpisode = () => useContext(EpisodeContext);
