import { Box, Link, Typography } from "@mui/material";

const Legal = () => {
  return (
    <Box margin={1}>
      <Typography fontWeight="800" fontSize={20}>
        Rechtliches
      </Typography>
      <Typography>
        Diese Seite ist NICHT im Besitz von Kosmos, Europa, der Familie Arthur
        oder anderen Personen, die in die Produktion der Serie Die drei ???
        eingebunden sind. Alle Schutzmarken, Logos und Copyrights gehören ihren
        jeweiligen Besitzern und werden hier zu den Bedingungen der
        Creative-Commons-Lizenz{" "}
        <Link href="https://creativecommons.org/licenses/by-sa/4.0/deed.de">
          CC BY-SA 4.0
        </Link>{" "}
        verwendet.
        <br />
        <b>
          Alle Bilder © copyright by Kosmos, Europa, Studios Hamburg, USM oder
          Random House.
        </b>
      </Typography>
    </Box>
  );
};

export default Legal;
