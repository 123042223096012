import {
  Accordion,
  AccordionSummary,
  Typography,
  AccordionDetails,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { DetailedEpisode } from "../../types/DetailedEpisode";
import { useNavigate } from "react-router-dom";

export interface ITracksAndSpeakersProps {
  episode: DetailedEpisode;
}

export const TracksAndSpeakers = (props: ITracksAndSpeakersProps) => {
  const navigate = useNavigate();

  const onSpeakerClick = (speaker: string) => {
    navigate(`/speakers/${speaker}`);
  };

  return (
    <>
      {props.episode.speakers.length > 0 ? (
        <Accordion>
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <Typography>Sprecher</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <TableContainer>
              <Table size="small">
                <TableHead>
                  <TableRow>
                    <TableCell sx={{ fontWeight: "bold" }}>Rolle</TableCell>
                    <TableCell sx={{ fontWeight: "bold" }}>Sprecher</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {props.episode.speakers
                    .sort((a, b) => a.sorting - b.sorting)
                    .map((speaker) => {
                      return (
                        <TableRow
                          key={speaker.role}
                          sx={{
                            "&:last-child td, &:last-child th": {
                              border: 0,
                            },
                          }}
                        >
                          <TableCell>{speaker.role}</TableCell>
                          <TableCell
                            sx={{ cursor: "pointer" }}
                            onClick={() =>
                              onSpeakerClick(speaker.sanitizedFullName)
                            }
                          >
                            {speaker.firstName} {speaker.lastName}
                          </TableCell>
                        </TableRow>
                      );
                    })}
                </TableBody>
              </Table>
            </TableContainer>
          </AccordionDetails>
        </Accordion>
      ) : (
        <></>
      )}
      {props.episode.tracks.length > 0 ? (
        <Accordion>
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <Typography>Tracks</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <TableContainer>
              <Table size="small">
                <TableHead>
                  <TableRow>
                    <TableCell sx={{ fontWeight: "bold" }}>Track</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {props.episode.tracks
                    .sort((a, b) => a.sorting - b.sorting)
                    .map((track) => {
                      return (
                        <TableRow key={track.id}>
                          <TableCell>{track.name}</TableCell>
                        </TableRow>
                      );
                    })}
                </TableBody>
              </Table>
            </TableContainer>
          </AccordionDetails>
        </Accordion>
      ) : (
        <></>
      )}
    </>
  );
};
