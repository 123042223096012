import { Box, Link, Typography } from "@mui/material";

const About = () => {
  return (
    <Box width={{ sx: "100%", md: "50%" }} margin={1}>
      <Typography fontWeight="800" fontSize={20}>
        Über dieses Fan-Portal
      </Typography>
      <Typography>
        Ich habe diese Webseite als großer Fan der drei Detektive für andere
        Fans entwickelt. Das Wiki und die offizielle Webseite waren mir als
        Suchoption zu umständlich und mir fehlte einige Funktionen.
        <br />
        <br />
        Mir ging es schon oft so: Auf dem Weg im Auto in den Urlaub und Lust auf
        eine Roadtrip passende Folge der drei Detektive? Oder im Winter unter
        der Decke und Lust auf eine Winter-Folge?
        <br />
        Nur leider verliert man in den über 200 Folgen mittlerweile den
        Überblick, welche Folge denn nun thematisch passt. Daher sind alle
        Episoden mit Tags versehen, wie zum Beispiel "Sport", "Winter",
        "Reisen", etc. So lassen sich ganz leicht passende Folgen finden, oder
        man lässt den Zufallsgenerator eine Folge aussuchen!
        <br />
        <br />
        Solltest du einen Vorschlag für einen neuen Tag haben, melde dich gerne!
        Ich bin immer gerne bereit Tags hinzuzufügen, oder zu überarbeiten.
        <br />
        <br />
        Da das Hosting der Webseite leider nicht umsonst ist und ich auch nur in
        meiner Freizeit hieran arbeite, würde ich mich sehr freuen, wenn ihr
        mich mit einer Pizza unterstützt! Damit lässt sich auch wesentlich
        besser entwickeln...
        <br />
        <br />
        <a href="https://www.buymeacoffee.com/alexheiming">
          <img style={{ height: "50px" }} src="/bmc-button.png" />
        </a>
        <br />
        Sonstige Bugs, Datenfehler oder Verbesserungsvorschläge nehme ich
        ebenfalls gerne entgegen! Meldet euch einfach unter{" "}
        <Link href="mailto:feedback.3fragezeichenportal@gmail.com">
          feedback.3fragezeichenportal@gmail.com
        </Link>
      </Typography>
    </Box>
  );
};

export default About;
