import {
  Box,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  SelectChangeEvent,
  Dialog,
  DialogContent,
  DialogActions,
  Button,
  Typography,
  Chip,
  OutlinedInput,
  IconButton,
} from "@mui/material";
import FilterAltOffIcon from "@mui/icons-material/FilterAltOff";
import { DatePicker } from "@mui/x-date-pickers";
import { useEffect, useState } from "react";
import { useEpisode } from "../../contexts/EpisodeContext";
import { orderByItems } from "../../types/OrderByItems";
import useTags from "../../api/useTags";

export interface FilterDialogProps {
  open: boolean;
  onClose: () => void;
}

const FilterDialog = (props: FilterDialogProps) => {
  const {
    order,
    orderBy,
    filters,
    setNameFilter,
    setEpisodeNumberFilter,
    setReleaseYearFromFilter,
    setReleaseYearToFilter,
    setTagsFilter,
    setOrder,
    setOrderBy,
  } = useEpisode();
  const { open, onClose } = props;
  const [tags, tagsLoading] = useTags();
  const [selectedTags, setSelectedTags] = useState<string[]>(
    filters.tagsFilter
  );
  const [name, setName] = useState<string>(filters.nameFilter);
  const [episodeNumber, setEpisodeNumber] = useState<string | null>(
    filters.episodeNumberFilter
  );
  const [releaseYearFrom, setReleaseYearFrom] = useState<
    Date | null | undefined
  >(filters.releaseYearFromFilter);
  const [releaseYearTo, setReleaseYearTo] = useState<Date | null | undefined>(
    filters.releaseYearToFilter
  );
  const [orderString, setOrderString] = useState<string>("episodeNumberAsc");

  const getPropertiesFromElement = (id: string): ["asc" | "desc", string] => {
    const element = orderByItems.filter((itm) => itm.id === id)?.[0];
    return [element.order, element.orderBy];
  };

  const handleSortChange = (event: SelectChangeEvent) => {
    const [newOrder, newOrderBy] = getPropertiesFromElement(event.target.value);
    setOrderString(event.target.value);
    setOrder(newOrder);
    setOrderBy(newOrderBy);
  };

  const handleChange = (event: SelectChangeEvent<typeof selectedTags>) => {
    const {
      target: { value },
    } = event;
    setSelectedTags(typeof value === "string" ? value.split(",") : value);
  };

  const handleClose = (
    event: {},
    reason: "backdropClick" | "escapeKeyDown"
  ) => {
    if (reason && reason !== "backdropClick") {
      onClose();
    }
  };

  const onCancel = () => {
    onClose();
  };

  const onSave = () => {
    if (name !== undefined) {
      setNameFilter(name);
    }
    if (episodeNumber !== null) {
      setEpisodeNumberFilter(episodeNumber);
    }
    if (releaseYearFrom !== undefined) {
      setReleaseYearFromFilter(releaseYearFrom);
    }
    if (releaseYearTo !== undefined) {
      setReleaseYearToFilter(releaseYearTo);
    }
    setTagsFilter(selectedTags);
    onClose();
  };

  const handleFilterReset = () => {
    setName("");
    setEpisodeNumber("");
    setReleaseYearFrom(null);
    setReleaseYearTo(null);
    setSelectedTags([]);
  };

  useEffect(() => {
    const element = orderByItems.filter(
      (itm) => itm.order === order && itm.orderBy === orderBy
    );
    if (element) {
      setOrderString(element[0].id);
    }
  }, []);

  return (
    <Dialog onClose={handleClose} open={open} keepMounted>
      <DialogContent>
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <Typography>Filter</Typography>
          <IconButton onClick={handleFilterReset}>
            <FilterAltOffIcon />
          </IconButton>
        </Box>
        <Box display="flex" flexDirection="column" rowGap={1} padding={1}>
          <TextField
            label="Name"
            variant="outlined"
            value={name}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
              setName(event.target.value);
            }}
          />
          <TextField
            type="number"
            label="Folgennummer"
            variant="outlined"
            value={episodeNumber}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
              setEpisodeNumber(event.target.value);
            }}
          />
          <DatePicker
            value={releaseYearFrom}
            slotProps={{ field: { clearable: true } }}
            onChange={(newValue) => setReleaseYearFrom(newValue)}
            label="Erscheinungsjahr von"
            views={["year"]}
          />
          <DatePicker
            value={releaseYearTo}
            slotProps={{ field: { clearable: true } }}
            onChange={(newValue) => setReleaseYearTo(newValue)}
            label="Erscheinungsjahr bis"
            views={["year"]}
          />
          <FormControl>
            <InputLabel>Tag</InputLabel>
            <Select
              multiple
              value={selectedTags}
              onChange={handleChange}
              input={<OutlinedInput label="Chip" />}
              renderValue={(selected) => (
                <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
                  {selected.map((value) => (
                    <Chip key={value} label={value} />
                  ))}
                </Box>
              )}
            >
              {tagsLoading ? (
                <MenuItem key="..." value="...">
                  ...
                </MenuItem>
              ) : (
                tags!.map((tag) => (
                  <MenuItem key={tag.id} value={tag.name}>
                    {tag.name}
                  </MenuItem>
                ))
              )}
            </Select>
          </FormControl>
          <Typography>Sortierung</Typography>
          <FormControl>
            <Select
              label="Sort"
              onChange={handleSortChange}
              value={orderString}
            >
              {orderByItems.map((orderByItem) => (
                <MenuItem key={orderByItem.id} value={orderByItem.id}>
                  {orderByItem.label}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={onCancel}>Abbrechen</Button>
        <Button onClick={onSave}>Anwenden</Button>
      </DialogActions>
    </Dialog>
  );
};

export default FilterDialog;
