import { Autocomplete, Box, Chip, Grid, TextField } from "@mui/material";
import { useEffect, useState } from "react";
import SearchIcon from "@mui/icons-material/Search";
import { useNavigate } from "react-router-dom";
import { SearchResult } from "../../types/SearchResult";

const SearchBarDesktop = () => {
  const navigate = useNavigate();
  const [options, setOptions] = useState<SearchResult[]>([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [loading, setLoading] = useState(false);

  const onSelectValue = (value: string | null) => {
    if (value) {
      setSearchTerm("");
      const key = options.filter((itm) => itm.label === value)[0];
      navigate(`episodes/${key.key}`);
    }
  };

  useEffect(() => {
    if (searchTerm) {
      setLoading(true);
      const delayDebounceFn = setTimeout(() => {
        fetch(
          `${process.env.REACT_APP_API_URL}/v1/search?searchTerm=${searchTerm}`
        )
          .then((res) => res.json())
          .then((json) => {
            setOptions(json);
            setLoading(false);
          })
          .catch((err) => console.log(err));
      }, 1000);
      return () => clearTimeout(delayDebounceFn);
    } else {
      setOptions([]);
    }
  }, [searchTerm]);

  return (
    <Box width="30%">
      <Autocomplete
        autoComplete
        popupIcon={<SearchIcon />}
        filterOptions={(x) => x}
        options={options}
        noOptionsText={searchTerm ? "Keine Fälle oder Sprecher gefunden" : "Die Suchlawine rollt nach Eingabe"}
        loading={loading}
        loadingText="Ermittle Ergebnisse..."
        onInputChange={(_event, value) => setSearchTerm(value as string)}
        onChange={(_event, value) => onSelectValue(value?.label!)}
        renderInput={(params) => (
          <TextField {...params} label="Suche Sprecher oder Folgen" />
        )}
        renderOption={(props, option) => {
          return (
            <li {...props}>
              <Grid container columnGap={1}>
                <Box>{option.label}</Box>
                <Chip
                  label={option.origin}
                  size="small"
                  variant="outlined"
                ></Chip>
              </Grid>
            </li>
          );
        }}
        sx={{
          "& .MuiAutocomplete-popupIndicator": { transform: "none" },
        }}
      ></Autocomplete>
    </Box>
  );
};

export default SearchBarDesktop;
