import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import FilterAltOffIcon from "@mui/icons-material/FilterAltOff";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import { useEffect, useState } from "react";
import {
  Box,
  Button,
  Chip,
  IconButton,
  OutlinedInput,
  TextField,
} from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers";
import { useEpisode } from "../../contexts/EpisodeContext";
import { orderByItems } from "../../types/OrderByItems";
import useTags from "../../api/useTags";
import useDatePickerBoundaries from "../../api/useDatePickerBoundaries";

const FilterAndSort = () => {
  const [orderString, setOrderString] = useState<string>("episodeNumberAsc");
  const [datePickerBoundary] = useDatePickerBoundaries();
  const [tags, tagsLoading] = useTags();
  const {
    order,
    orderBy,
    filters,
    setNameFilter,
    setEpisodeNumberFilter,
    setReleaseYearFromFilter,
    setReleaseYearToFilter,
    setTagsFilter,
    setOrder,
    setOrderBy,
    resetFilters,
  } = useEpisode();
  const [selectedTags, setSelectedTags] = useState<string[]>(
    filters.tagsFilter
  );

  const getPropertiesFromElement = (id: string): ["asc" | "desc", string] => {
    const element = orderByItems.filter((itm) => itm.id === id)?.[0];
    return [element.order, element.orderBy];
  };

  const handleSortChange = (event: SelectChangeEvent) => {
    const [newOrder, newOrderBy] = getPropertiesFromElement(event.target.value);
    setOrderString(event.target.value);
    setOrder(newOrder);
    setOrderBy(newOrderBy);
  };

  const handleChange = (event: SelectChangeEvent<typeof selectedTags>) => {
    const {
      target: { value },
    } = event;
    setSelectedTags(typeof value === "string" ? value.split(",") : value);
    setTagsFilter(typeof value === "string" ? value.split(",") : value);
  };

  const handleFilterReset = () => {
    resetFilters();
  };

  useEffect(() => {
    const element = orderByItems.filter(
      (itm) => itm.order === order && itm.orderBy === orderBy
    );
    if (element) {
      setOrderString(element[0].id);
    }
  }, []);

  useEffect(() => {
    setSelectedTags(filters.tagsFilter);
  }, [filters.tagsFilter]);

  return (
    <Box display="flex" columnGap={1}>
      <TextField
        label="Name"
        variant="outlined"
        value={filters.nameFilter}
        onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
          setNameFilter(event.target.value);
        }}
      />
      <TextField
        type="number"
        label="Folgennummer"
        variant="outlined"
        value={filters.episodeNumberFilter}
        onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
          setEpisodeNumberFilter(event.target.value);
        }}
      />
      <DatePicker
        value={filters.releaseYearFromFilter}
        onChange={(newValue) => setReleaseYearFromFilter(newValue)}
        label="Erscheinungsjahr von"
        views={["year"]}
        slotProps={{ field: { clearable: true } }}
        minDate={datePickerBoundary?.firstEpisodeYear}
        maxDate={datePickerBoundary?.currentEpisodeYear}
      />
      <DatePicker
        value={filters.releaseYearToFilter}
        onChange={(newValue) => setReleaseYearToFilter(newValue)}
        label="Erscheinungsjahr bis"
        views={["year"]}
        slotProps={{ field: { clearable: true } }}
        minDate={datePickerBoundary?.firstEpisodeYear}
        maxDate={datePickerBoundary?.currentEpisodeYear}
      />
      <FormControl sx={{ width: 300 }}>
        <InputLabel>Tag</InputLabel>
        <Select
          multiple
          value={selectedTags}
          onChange={handleChange}
          input={<OutlinedInput label="Chip" />}
          renderValue={(selected) => (
            <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
              {selected.map((value) => (
                <Chip key={value} label={value} />
              ))}
            </Box>
          )}
        >
          {tagsLoading ? (
            <MenuItem key="..." value="...">
              ...
            </MenuItem>
          ) : (
            tags!.map((tag) => (
              <MenuItem key={tag.id} value={tag.name}>
                {tag.name}
              </MenuItem>
            ))
          )}
        </Select>
      </FormControl>
      <IconButton onClick={handleFilterReset}>
        <FilterAltOffIcon />
      </IconButton>
      <Box flex={1} />
      <FormControl>
        <InputLabel>Sortierung</InputLabel>
        <Select label="Sort" onChange={handleSortChange} value={orderString}>
          {orderByItems.map((orderByItem) => (
            <MenuItem key={orderByItem.id} value={orderByItem.id}>
              {orderByItem.label}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </Box>
  );
};

export default FilterAndSort;
