import { Box, Skeleton, Grid, Card, CardContent } from "@mui/material";

const SpeakerDetailSkeleton = () => {
  return (
    <Box display="flex" flexDirection="column" rowGap={1} margin={2}>
      <Skeleton variant="text" width="30%" height={40} />
      <Grid container spacing={2}>
        <Grid container item spacing={3}>
          {[...Array(5)].map((_, i) => (
            <Grid item xs={12} sm={6} md={4} lg={2.4} xl={2} key={i}>
              <Card>
                <CardContent sx={{ margin: "0.4em" }}>
                  <Skeleton
                    variant="rectangular"
                    height="250px"
                    width="100%"
                  ></Skeleton>
                  <Skeleton variant="text" width="90%" />
                  <Skeleton variant="text" width="30%" />
                  <Skeleton variant="text" width="20%" />
                </CardContent>
              </Card>
            </Grid>
          ))}
        </Grid>
      </Grid>
    </Box>
  );
};

export default SpeakerDetailSkeleton;
