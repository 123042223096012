import { Box, Button } from "@mui/material";
import { DetailedEpisode } from "../../types/DetailedEpisode";

export interface ILinkButtonsProps {
  episode: DetailedEpisode;
}

export const LinkButtons = (props: ILinkButtonsProps) => {
  return (
    <Box display="flex" columnGap={1}>
      {props.episode.spotifyLink !== null ? (
        <Button
          sx={{
            backgroundColor: "black",
            height: "50px",
            ":hover": {
              backgroundColor: "darkgray",
            },
          }}
          target="_blank"
          href={props.episode.spotifyLink!}
        >
          <img
            src="\spotify_icon.png"
            width="40"
            style={{ aspectRatio: "1/1" }}
          />
        </Button>
      ) : (
        <></>
      )}
      {props.episode.appleMusicLink !== null ? (
        <Button
          sx={{
            backgroundColor: "black",
            height: "50px",
            ":hover": {
              backgroundColor: "darkgray",
            },
          }}
          target="_blank"
          href={props.episode.appleMusicLink!}
        >
          <img
            src="\apple_music_icon.png"
            width="40"
            style={{ aspectRatio: "1/1" }}
          />
        </Button>
      ) : (
        <></>
      )}
      {props.episode.wikiLink !== null ? (
        <Button
          sx={{
            height: "50px",
            backgroundColor: "black",
            ":hover": {
              color: "black",
              backgroundColor: "darkgray",
            },
            color: "white",
            display: "flex",
            flexDirection: "column",
          }}
          target="_blank"
          href={props.episode.wikiLink!}
        >
          <img
            src="\drei_fragezeichen_icon.png"
            style={{ aspectRatio: "1/1", maxWidth: "20px" }}
          />
          WIKI
        </Button>
      ) : (
        <></>
      )}
      {props.episode.officialPageLink !== null ? (
        <Button
          sx={{
            backgroundColor: "black",
            height: "50px",
            ":hover": {
              backgroundColor: "darkgray",
            },
          }}
          target="_blank"
          href={props.episode.officialPageLink!}
        >
          <img
            src="\drei_fragezeichen_icon.png"
            width="40"
            style={{ aspectRatio: "1/1" }}
          />
        </Button>
      ) : (
        <></>
      )}
    </Box>
  );
};
