import { Box, Grid, Skeleton } from "@mui/material";

export const EpisodeDetailSkeleton = () => {
  return (
    <Grid container columnGap={{ md: 1 }} rowGap={2} padding={1}>
      <Grid item xs={12} md={4.9} lg={3.9} xl={3.5}>
        <Skeleton
          variant="rectangular"
          sx={{
            width: "100%",
            height: { xs: 400, sm: 550, md: 425, lg: 525 },
          }}
        />
      </Grid>
      <Grid item xs={12} md={6.9} lg={6} xl={6}>
        <Grid item container direction="column" rowGap={1}>
          <Grid item container direction="column" rowGap={1}>
            <Skeleton variant="text" width="40%" sx={{ fontSize: 35 }} />
            <Skeleton variant="text" width="10%" />
            <Skeleton variant="rectangular" height={225} />
            <Skeleton variant="rounded" height={50} width={300} />
            <Box sx={{ display: { xs: "block", md: "none" } }}>
              <Skeleton variant="rounded" height={30} />
              <Skeleton variant="rounded" height={30} />
            </Box>
          </Grid>
        </Grid>
      </Grid>
      <Grid
        item
        container
        sx={{ display: { xs: "none", md: "block" } }}
        xs={12}
        lg={10}
      >
        <Skeleton variant="rounded" height={30} />
        <Skeleton variant="rounded" height={30} />
      </Grid>
    </Grid>
  );
};
