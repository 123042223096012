import { useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  Box,
  Divider,
  Drawer,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import HomeIcon from "@mui/icons-material/Home";
import QuestionMarkIcon from "@mui/icons-material/QuestionMark";
import CasinoIcon from "@mui/icons-material/Casino";
import InterpreterModeIcon from "@mui/icons-material/InterpreterMode";
import InfoIcon from "@mui/icons-material/Info";
import { DarkModeToggle } from "./DarkModeToggle";
import SearchBarDesktop from "./SearchBarDesktop";
import SearchBarMobile from "./SearchBarMobile";

export const Header = () => {
  const theme = useTheme();
  const navigate = useNavigate();
  const [searchBarClosed, setSearchBarClosed] = useState(true);
  const [drawerOpen, setDrawerOpen] = useState(false);
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));

  const toggleSearchBar = () => {
    setSearchBarClosed((prev) => !prev);
  };

  const openDrawer = () => {
    setDrawerOpen(true);
  };

  const closeDrawer = () => {
    setDrawerOpen(false);
  };

  const navigateToDashboard = () => {
    navigate("episodes");
    setDrawerOpen(false);
  };

  const navigateToSpeakers = () => {
    navigate("speakers");
    setDrawerOpen(false);
  };

  const navigateToRandomEpisode = () => {
    navigate("random");
    setDrawerOpen(false);
  };

  const navigateToAbout = () => {
    navigate("about");
    setDrawerOpen(false);
  };

  const navigateToLegal = () => {
    navigate("legal");
    setDrawerOpen(false);
  };

  return (
    <>
      <Box
        display="flex"
        flexDirection="row"
        alignItems="center"
        justifyContent="flex-end"
        height="4em"
        marginRight={1}
      >
        <Drawer open={drawerOpen} onClose={closeDrawer}>
          <Box sx={{ width: 250, height: "100%" }} role="presentation">
            <List
              sx={{ display: "flex", flexDirection: "column", height: "100%" }}
            >
              <ListItem disablePadding>
                <ListItemButton onClick={navigateToDashboard}>
                  <ListItemIcon>
                    <HomeIcon />
                  </ListItemIcon>
                  <ListItemText primary="Folgen" />
                </ListItemButton>
              </ListItem>
              <ListItem disablePadding>
                <ListItemButton onClick={navigateToSpeakers}>
                  <ListItemIcon>
                    <InterpreterModeIcon />
                  </ListItemIcon>
                  <ListItemText primary="Sprecher" />
                </ListItemButton>
              </ListItem>
              <ListItem disablePadding>
                <ListItemButton onClick={navigateToRandomEpisode}>
                  <ListItemIcon>
                    <CasinoIcon />
                  </ListItemIcon>
                  <ListItemText primary="Zufällige Episode" />
                </ListItemButton>
              </ListItem>
              <Box flex={1} />
              <ListItem disablePadding>
                <ListItemButton onClick={navigateToAbout}>
                  <ListItemIcon>
                    <InfoIcon />
                  </ListItemIcon>
                  <ListItemText primary="Über uns" />
                </ListItemButton>
              </ListItem>
              <ListItem disablePadding>
                <ListItemButton onClick={navigateToLegal}>
                  <ListItemIcon>
                    <QuestionMarkIcon />
                  </ListItemIcon>
                  <ListItemText primary="Rechtliches" />
                </ListItemButton>
              </ListItem>
            </List>
          </Box>
        </Drawer>
        <Box flex={1}>
          <IconButton onClick={openDrawer}>
            <MenuIcon fontSize="large" />
          </IconButton>
        </Box>
        {searchBarClosed ? <DarkModeToggle /> : <></>}
        {isMobile ? (
          <SearchBarMobile onToggleSearchBar={toggleSearchBar} />
        ) : (
          <SearchBarDesktop />
        )}
      </Box>
    </>
  );
};
