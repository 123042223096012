interface SortingProperty {
  id: string;
  label: string;
  orderBy: string;
  order: "asc" | "desc";
}

export const orderByItems: readonly SortingProperty[] = [
  {
    id: "releaseDateNewest",
    label: "Älteste",
    orderBy: "releaseDate",
    order: "asc",
  },
  {
    id: "releaseDateOldest",
    label: "Neuste",
    orderBy: "releaseDate",
    order: "desc",
  },
  {
    id: "titleAZ",
    label: "A -> Z",
    orderBy: "title",
    order: "asc",
  },
  {
    id: "titleZA",
    label: "Z -> A",
    orderBy: "title",
    order: "desc",
  },
  {
    id: "episodeNumberAsc",
    label: "Folgennummer aufsteigend",
    orderBy: "episodeNumber",
    order: "asc",
  },
  {
    id: "episodeNumberDesc",
    label: "Folgennummer absteigend",
    orderBy: "episodeNumber",
    order: "desc",
  },
];
