import { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
  Box,
  Chip,
  Grid,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { LinkButtons } from "./LinkButtons";
import { EpisodeDetailSkeleton } from "./EpisodeDetailSkeleton";
import { TracksAndSpeakers } from "./TracksAndSpeakers";
import useEpisodeDetail from "../../api/useEpisodeDetail";
import { useEpisode } from "../../contexts/EpisodeContext";

export const EpisodeDetail = () => {
  const { title } = useParams();
  const navigate = useNavigate();
  const { setTagsFilter } = useEpisode();
  const theme = useTheme();
  const [episode, isLoading, error] = useEpisodeDetail(title ?? "");
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));

  const onChipClick = (tagName: string) => {
    setTagsFilter([tagName]);
    navigate("/episodes");
  };

  useEffect(() => {
    if (!isLoading && error) {
      navigate("/not-found");
    }
  }, [navigate, isLoading, error]);

  return (
    <Box marginBottom={isMobile ? 3 : 0}>
      {isLoading || episode === null ? (
        <EpisodeDetailSkeleton />
      ) : (
        <Grid container columnGap={{ md: 1 }} rowGap={2} padding={1}>
          <Grid item xs={12} md={4.9} lg={3.9} xl={3.5}>
            <Box
              component="img"
              sx={{
                width: "100%",
                height: { xs: 400, sm: 550, md: 425, lg: 525 },
              }}
              src={`${process.env.REACT_APP_API_URL}/${episode.imagePath}`}
            ></Box>
          </Grid>
          <Grid item xs={12} md={6.9} lg={6} xl={6}>
            <Grid item container direction="column" rowGap={1}>
              <Typography fontSize={35}>
                {episode.episodeNumber
                  ? `${episode.episodeNumber?.toString().padStart(3, "0")} - ${
                      episode.title
                    }`
                  : episode.title}
              </Typography>
              <Typography fontStyle={"italic"}>Erscheinungsdatum</Typography>
              <Typography>
                {episode.releaseDate &&
                  new Date(episode.releaseDate).toLocaleDateString()}
              </Typography>
              <Typography>{episode.description}</Typography>
              <LinkButtons episode={episode}></LinkButtons>
              <Box>
                {episode.tags?.map((tag) => (
                  <Chip
                    clickable
                    onClick={() => onChipClick(tag.name)}
                    key={tag.id}
                    label={tag.name}
                    sx={{ backgroundColor: tag.colorCode, marginLeft: 1 }}
                  />
                ))}
              </Box>
              <Box sx={{ display: { xs: "block", md: "none" } }}>
                <TracksAndSpeakers episode={episode} />
              </Box>
            </Grid>
          </Grid>
          <Grid
            item
            sx={{ display: { xs: "none", md: "block" } }}
            xs={12}
            lg={10}
          >
            <TracksAndSpeakers episode={episode} />
          </Grid>
        </Grid>
      )}
    </Box>
  );
};
