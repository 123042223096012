import Dashboard from "./components/Dashboard/Dashboard";
import { Navigate, Route, Routes } from "react-router-dom";
import { EpisodeDetail } from "./components/EpisodeDetail/EpisodeDetail";
import { EpisodeProvider } from "./contexts/EpisodeContext";
import { CssBaseline, Divider } from "@mui/material";
import { Header } from "./components/TopBar/Header";
import { ColorModeProvider } from "./contexts/ColorModeContext";
import Speakers from "./components/Speakers/Speakers";
import SpeakerDetail from "./components/Speakers/SpeakerDetail";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFnsV3";
import NotFound from "./components/NotFound";
import StartWheel from "./components/RandomEpisode/StartWheel";
import About from "./components/About/About";
import Legal from "./components/Legal/Legal";

function App() {
  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <ColorModeProvider>
        <CssBaseline />
        <EpisodeProvider>
          <Header />
          <Routes>
            <Route path="episodes">
              <Route index element={<Dashboard />} />
              <Route path=":title" element={<EpisodeDetail />} />
            </Route>
            <Route path="speakers">
              <Route index element={<Speakers />} />
              <Route path=":name" element={<SpeakerDetail />} />
            </Route>
            <Route path="random">
              <Route index element={<StartWheel />} />
            </Route>
            <Route path="about">
              <Route index element={<About />} />
            </Route>
            <Route path="legal">
              <Route index element={<Legal />} />
            </Route>
            <Route path="*" element={<Navigate to="episodes" />} />
            <Route path="not-found" element={<NotFound />} />
          </Routes>
        </EpisodeProvider>
      </ColorModeProvider>
    </LocalizationProvider>
  );
}

export default App;
