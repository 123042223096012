import { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Box, Grid, Typography } from "@mui/material";
import EpisodeCard from "../Dashboard/EpisodeCard";
import SpeakerDetailSkeleton from "./SpeakerDetailSkeleton";
import useSpeakerDetail from "../../api/useSpeakerDetail";

const SpeakerDetail = () => {
  const { name } = useParams();
  const navigate = useNavigate();
  const [speaker, isLoading, error] = useSpeakerDetail(name ?? "");

  useEffect(() => {
    if (!isLoading && error) {
      navigate("/not-found");
    }
  }, [navigate, isLoading, error]);

  return (
    <>
      {isLoading || speaker === null ? (
        <SpeakerDetailSkeleton />
      ) : (
        <Box display="flex" flexDirection="column" rowGap={1} margin={2}>
          <Typography variant="h3">{speaker.fullName}</Typography>
          <Box display="flex">
            <Grid container spacing={2}>
              <Grid container item spacing={3}>
                {speaker.episodes.map((episodeRole) => {
                  return (
                    <Grid
                      item
                      xs={12}
                      sm={6}
                      md={4}
                      lg={2.4}
                      xl={2}
                      key={episodeRole.id}
                    >
                      <EpisodeCard
                        episode={episodeRole.episode}
                        role={episodeRole.role}
                      />
                    </Grid>
                  );
                })}
              </Grid>
            </Grid>
          </Box>
        </Box>
      )}
    </>
  );
};

export default SpeakerDetail;
