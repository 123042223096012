import {
  Autocomplete,
  Box,
  Chip,
  Grid,
  IconButton,
  TextField,
} from "@mui/material";
import { useEffect, useState } from "react";
import SearchIcon from "@mui/icons-material/Search";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useNavigate } from "react-router-dom";
import { SearchResult } from "../../types/SearchResult";

export interface ISearchBarProps {
  onToggleSearchBar(): void;
}

const SearchBarMobile = (props: ISearchBarProps) => {
  const navigate = useNavigate();
  const [options, setOptions] = useState<SearchResult[]>([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [value, setValue] = useState<null | SearchResult>(null);
  const [showSearchBar, setShowSearchBar] = useState(false);
  const [loading, setLoading] = useState(false);

  const handleChange = () => {
    setShowSearchBar((prev) => !prev);
    props.onToggleSearchBar();
  };

  const onSelectValue = (option: SearchResult | null) => {
    setValue(option);
    if (option) {
      setShowSearchBar((prev) => !prev);
      props.onToggleSearchBar();
      const key = options.filter((itm) => itm.label === option.label)[0];
      const route =
        option.origin.toLowerCase() === "speaker"
          ? `speakers/${key.key}`
          : `episodes/${key.key}`;
      navigate(route);
      setValue(null);
      setSearchTerm("");
    }
  };

  useEffect(() => {
    if (searchTerm) {
      setLoading(true);
      const delayDebounceFn = setTimeout(() => {
        fetch(
          `${process.env.REACT_APP_API_URL}/v1/search?searchTerm=${searchTerm}`
        )
          .then((res) => res.json())
          .then((json) => {
            setOptions(json);
            setLoading(false);
          })
          .catch((err) => console.log(err));
      }, 1000);
      return () => clearTimeout(delayDebounceFn);
    } else {
      setOptions([]);
    }
  }, [searchTerm]);

  return (
    <>
      <IconButton
        sx={{ display: showSearchBar ? "block" : "none" }}
        onClick={handleChange}
      >
        <ArrowBackIcon />
      </IconButton>
      <Box
        justifyContent="flex-end"
        sx={{
          display: showSearchBar ? "flex" : "none",
          width: "100%",
        }}
      >
        <Autocomplete
          value={value}
          inputValue={searchTerm}
          autoComplete
          popupIcon={<SearchIcon />}
          filterOptions={(x) => x}
          options={options}
          noOptionsText={"Keine Fälle oder Sprecher gefunden"}
          loading={loading}
          loadingText="Ermittle Ergebnisse..."
          onInputChange={(_event, value) => setSearchTerm(value as string)}
          onChange={(_event, value) => onSelectValue(value)}
          renderInput={(params) => (
            <TextField {...params} label="Search speakers or episodes" />
          )}
          renderOption={(props, option) => {
            return (
              <li {...props}>
                <Grid container columnGap={1}>
                  <Box>{option.label}</Box>
                  <Chip
                    label={option.origin}
                    size="small"
                    variant="outlined"
                  ></Chip>
                </Grid>
              </li>
            );
          }}
          sx={{
            "& .MuiAutocomplete-popupIndicator": { transform: "none" },
            width: "100%",
          }}
        ></Autocomplete>
      </Box>
      <IconButton
        sx={{ display: showSearchBar ? "none" : "block" }}
        onClick={handleChange}
      >
        <SearchIcon />
      </IconButton>
    </>
  );
};

export default SearchBarMobile;
