import { forwardRef, useEffect } from "react";
import useEpisodeDetail from "../../api/useEpisodeDetail";
import EpisodeCard from "../Dashboard/EpisodeCard";
import { Button, Box, Card, CardContent, Skeleton } from "@mui/material";
import EpisodeCardSkeleton from "../Dashboard/EpisodeCardSkeleton";
import { useNavigate } from "react-router-dom";

export interface ChosenEpisodeProps {
  title: string;
  onRestart(): void;
}

const ChosenEpisode = forwardRef<HTMLDivElement, ChosenEpisodeProps>(function (
  props,
  ref
) {
  const [episode, isLoading] = useEpisodeDetail(props.title);

  return (
    <Box
      display="flex"
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
      ref={ref}
      width={"100%"}
    >
      <Box
        sx={{
          width: { xs: "90%", sm: "60%", md: "40%", lg: "30%", xl: "30%" },
        }}
      >
        {isLoading || episode === null ? (
          <Box>
            <EpisodeCardSkeleton />
          </Box>
        ) : (
          <Box>
            <EpisodeCard episode={episode} />
          </Box>
        )}
        <Button
          sx={{ marginTop: 1 }}
          fullWidth
          variant="contained"
          onClick={props.onRestart}
        >
          Neustart
        </Button>
      </Box>
    </Box>
  );
});
export default ChosenEpisode;
