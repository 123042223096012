import {
  Card,
  CardContent,
  CardMedia,
  Typography,
  useTheme,
} from "@mui/material";
import { Episode } from "../../types/Episode";
import { useNavigate } from "react-router-dom";
import { useState } from "react";

export interface EpisodeCardProps {
  episode: Episode;
  role?: string;
}

const EpisodeCard = (props: EpisodeCardProps) => {
  const navigate = useNavigate();
  const theme = useTheme();
  const [loading, setLoading] = useState(true);

  const openDetailPage = () => {
    navigate(`/episodes/${props.episode.sanitizedTitle}`);
  };

  const onImageLoaded = () => {
    setLoading(false);
  };

  return (
    <Card sx={{ cursor: "pointer", minHeight: 430 }} onClick={openDetailPage}>
      <CardMedia
        style={{
          display: loading ? "block" : "none",
          backgroundColor:
            theme.palette.mode === "dark" ? "black" : "lightgray",
          padding: "1em",
        }}
        component="img"
        image="/Site-logo.png"
        title="Folgen-Cover"
      />
      <CardMedia
        style={{
          display: loading ? "none" : "block",
          padding: "0.5em",
        }}
        onLoad={onImageLoaded}
        component="img"
        image={`${process.env.REACT_APP_API_URL}/${props.episode.imagePath}`}
        title="Folgen-Cover"
      />
      <CardContent>
        {props.role ? (
          <Typography sx={{ fontSize: 20 }}>Als {props.role}</Typography>
        ) : (
          <></>
        )}
        <Typography sx={{ fontSize: props.role ? 17 : 20 }}>
          {props.episode.episodeNumber
            ? `${props.episode.episodeNumber?.toString().padStart(3, "0")} - ${
                props.episode.title
              }`
            : props.episode.title}
        </Typography>
        <Typography sx={{ fontSize: 14, fontWeight: "light" }}>
          {props.episode.releaseDate &&
            new Date(props.episode.releaseDate).toLocaleDateString()}
        </Typography>
        <Typography sx={{ fontSize: 14, fontWeight: "light" }}>
          {props.episode.episodeNumber ? "Hauptreihe" : "Special"}
        </Typography>
      </CardContent>
    </Card>
  );
};

export default EpisodeCard;
