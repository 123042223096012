import { useEffect, useState } from "react";

function useApiClient<T>(
  requestUri: string
): [T | null, boolean, string | null] {
  const [results, setResults] = useState<T | null>(null);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    setIsLoading(true);
    fetch(requestUri)
      .then(async (response) => {
        if (response.ok) {
          setResults(await response.json());
          setError(null);
          setIsLoading(false);
        } else {
          setError("Ein Fehler ist aufgetreten");
          setIsLoading(false);
        }
      })
      .catch((err) => {
        setError("Ein Fehler ist aufgetreten");
        setIsLoading(false);
      });
  }, [requestUri]);

  return [results, isLoading, error];
}

export default useApiClient;
