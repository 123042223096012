import { Box, Typography } from "@mui/material";

const NotFound = () => {
  return (
    <Box
      display="flex"
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
    >
      <Typography fontSize={40}>404</Typography>
      <Box height="2em" />
      <Typography>
        Diese Seite ist wirklich ein spezialgelagerter Sonderfall...Hier ist
        jedoch nichts mehr zu finden!
      </Typography>
    </Box>
  );
};

export default NotFound;
