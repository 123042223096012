import {
  Box,
  Pagination,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
  TextField,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { Ref, useEffect, useState } from "react";
import { PaginatedSpeakers } from "../../types/PaginatedSpeakers";
import { useNavigate } from "react-router-dom";
import SpeakersSkeleton from "./SpeakersSkeleton";
import { HeadCell, desktopHeadCells, mobileHeadCells } from "./HeadCells";
import React from "react";

const Speakers = () => {
  const navigate = useNavigate();
  const theme = useTheme();
  const [headCells, setHeadCells] = useState<HeadCell[]>([]);
  const isDesktop = useMediaQuery(theme.breakpoints.up("sm"));
  const [speakerList, setSpeakerList] = useState<PaginatedSpeakers | null>(
    null
  );
  const [isLoading, setIsLoading] = useState(false);
  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const [order, setOrder] = useState<"asc" | "desc">("asc");
  const [orderBy, setOrderBy] = useState("");
  const [filterFirstName, setFilterFirstName] = useState("");
  const [filterLastName, setFilterLastName] = useState("");

  useEffect(() => {
    setIsLoading(true);
    let url = `${process.env.REACT_APP_API_URL}/v1/speaker?page=${page}&pageSize=${pageSize}`;
    if (filterFirstName) {
      url += `&firstName=${filterFirstName}`;
    }
    if (filterLastName) {
      url += `&lastName=${filterLastName}`;
    }
    if (orderBy && order) {
      url += `&orderBy=${orderBy}&order=${order}`;
    }
    fetch(url)
      .then((res) => res.json())
      .then((json) => {
        setSpeakerList(json);
        setIsLoading(false);
      });
  }, [page, pageSize, filterFirstName, filterLastName, order, orderBy]);

  useEffect(() => {
    if (isDesktop) {
      setHeadCells(desktopHeadCells);
    } else {
      setHeadCells(mobileHeadCells);
    }
  }, [isDesktop]);

  const onRowClick = (sanitizedFullName: string) => {
    navigate(`${sanitizedFullName}`);
  };

  const onFilterFirstName = (event: React.ChangeEvent<HTMLInputElement>) => {
    setFilterFirstName(event.target.value);
  };

  const onTextfieldKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key == "Enter") {
      let htmlInputElement = event.target as HTMLInputElement;
      htmlInputElement.blur();
    }
  };

  const onFilterLastName = (event: React.ChangeEvent<HTMLInputElement>) => {
    setFilterLastName(event.target.value);
  };

  const handlePageChange = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => {
    setPage(newPage);
  };

  const handlePageSizeChange = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setPageSize(parseInt(event.target.value, 10));
  };

  const createSortHandler =
    (property: string) => (event: React.MouseEvent<unknown>) => {
      const isAsc = orderBy === property && order === "asc";
      setOrder(isAsc ? "desc" : "asc");
      setOrderBy(property);
    };

  return (
    <Box margin={2}>
      <Typography fontSize={32}>Sprecher</Typography>
      <Box display="flex" columnGap={1}>
        <TextField
          label="Vorname"
          variant="outlined"
          onKeyDown={onTextfieldKeyDown}
          onChange={onFilterFirstName}
        />
        <TextField
          label="Nachname"
          variant="outlined"
          onKeyDown={onTextfieldKeyDown}
          onChange={onFilterLastName}
        />
      </Box>
      {isLoading || speakerList === null ? (
        <SpeakersSkeleton pageSize={pageSize} />
      ) : (
        <Box>
          <Paper sx={{ overflowX: "auto" }}>
            <TableContainer sx={{ overflowX: "initial" }}>
              <Table stickyHeader>
                <TableHead>
                  <TableRow>
                    {headCells.map((headCell) =>
                      headCell.sortable ? (
                        <TableCell
                          key={headCell.id}
                          align={headCell.numeric ? "right" : "left"}
                          sortDirection={
                            orderBy === headCell.id ? order : false
                          }
                        >
                          <TableSortLabel
                            active={orderBy === headCell.id}
                            direction={orderBy === headCell.id ? order : "asc"}
                            onClick={createSortHandler(headCell.id)}
                          >
                            {headCell.label}
                          </TableSortLabel>
                        </TableCell>
                      ) : (
                        <TableCell key={headCell.id}>
                          {headCell.label}
                        </TableCell>
                      )
                    )}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {speakerList?.speakers.map((row) => (
                    <TableRow
                      hover
                      key={row.id}
                      onClick={() => onRowClick(row.sanitizedFullName)}
                    >
                      <TableCell
                        sx={{ display: { xs: "table-cell", sm: "none" } }}
                      >
                        {row.fullName}
                      </TableCell>
                      <TableCell
                        sx={{ display: { xs: "none", sm: "table-cell" } }}
                      >
                        {row.firstName}
                      </TableCell>
                      <TableCell
                        sx={{ display: { xs: "none", sm: "table-cell" } }}
                      >
                        {row.lastName}
                      </TableCell>
                      <TableCell align="right">{row.episodeCount}</TableCell>
                      <TableCell
                        sx={{ display: { xs: "none", sm: "table-cell" } }}
                      >
                        {row.firstEpisodeTitle}
                      </TableCell>
                      <TableCell
                        sx={{ display: { xs: "none", sm: "table-cell" } }}
                      >
                        {row.lastEpisodeTitle}
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
                <TablePagination
                  sx={{ display: { xs: "none", md: "table-cell" } }}
                  labelRowsPerPage="Sprecher pro Seite"
                  showFirstButton
                  showLastButton
                  rowsPerPageOptions={[10, 25, 50]}
                  count={speakerList.paginationMetadata.count}
                  rowsPerPage={speakerList.paginationMetadata.pageSize}
                  page={speakerList.paginationMetadata.page}
                  onPageChange={handlePageChange}
                  onRowsPerPageChange={handlePageSizeChange}
                />
              </Table>
            </TableContainer>
          </Paper>
          <Box
            display="flex"
            justifyContent="center"
            marginTop="1em"
            width="100%"
          >
            <Pagination
              sx={{ display: { xs: "block", md: "none" } }}
              count={speakerList.paginationMetadata.pageCount}
              page={speakerList.paginationMetadata.page + 1}
              onChange={(_, newPage) => setPage(newPage - 1)}
              size="small"
              showFirstButton
              showLastButton
            />
          </Box>
        </Box>
      )}
    </Box>
  );
};

export default Speakers;
