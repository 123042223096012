import {
  Box,
  Button,
  Chip,
  FormControl,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select,
  SelectChangeEvent,
  Slider,
  Typography,
} from "@mui/material";
import WheelOfFortune from "./WheelOfFortune";
import { useCallback, useEffect, useMemo, useState } from "react";
import useTags from "../../api/useTags";
import { TitleResponse } from "../../types/TitleResponse";

const StartWheel = () => {
  const [isSpinning, setIsSpinning] = useState(false);
  const [tags, tagsLoading] = useTags();
  const [titles, setTitles] = useState<TitleResponse[]>([]);
  const [selectedTags, setSelectedTags] = useState<string[]>([]);
  const sliderMin = 1;
  const sliderMax = 228;
  const [value, setValue] = useState<number[]>([sliderMin, sliderMax]);
  const minDistance = 10;

  const handleEpisodeRangeChange = (
    event: Event,
    newValue: number | number[],
    activeThumb: number
  ) => {
    if (!Array.isArray(newValue)) {
      return;
    }

    if (activeThumb === 0) {
      setValue([Math.min(newValue[0], value[1] - minDistance), value[1]]);
    } else {
      setValue([value[0], Math.max(newValue[1], value[0] + minDistance)]);
    }
  };

  const onSpinWheel = () => {
    setIsSpinning(true);
  };

  const onRestart = () => {
    setIsSpinning(false);
  };

  const handleChange = (event: SelectChangeEvent<typeof selectedTags>) => {
    const {
      target: { value },
    } = event;
    setSelectedTags(typeof value === "string" ? value.split(",") : value);
  };

  const fetchTitles = useCallback(() => {
    const url = `${
      process.env.REACT_APP_API_URL
    }/v1/episode/titles?tags=${selectedTags?.join(",")}&episodeNumberFrom=${
      value[0]
    }&episodeNumberTo=${value[1]}`;
    fetch(url)
      .then((res) => res.json())
      .then((json) => setTitles(json))
      .catch((err) => console.log(err));
  }, [selectedTags, value]);

  useEffect(() => {
    fetchTitles();
  }, [selectedTags, value[0], value[1]]);

  useEffect(() => {
    fetchTitles();
  }, []);

  return (
    <Box
      display="flex"
      flexDirection="column"
      justifyContent="center"
      rowGap={1}
      alignItems="center"
      sx={{
        height: ["calc(100dvh - 6em)", "calc(100vh - 6em)"],
      }}
    >
      {isSpinning ? (
        <WheelOfFortune titles={titles} onRestart={onRestart} />
      ) : (
        <>
          <Box
            display="flex"
            flexDirection="column"
            alignItems="center"
            width="100%"
            padding={1}
          >
            <Typography id="non-linear-slider" gutterBottom>
              Folgen
            </Typography>
            <Slider
              min={sliderMin}
              max={sliderMax}
              step={1}
              getAriaLabel={() => "Eingrenzung Folgen"}
              value={value}
              onChange={handleEpisodeRangeChange}
              valueLabelDisplay="auto"
              disableSwap
              marks={[
                { value: sliderMin, label: sliderMin },
                { value: sliderMax, label: sliderMax },
              ]}
              sx={{
                width: {
                  xs: "90%",
                  sm: "60%",
                  md: "50%",
                  lg: "30%",
                  xl: "30%",
                },
              }}
            />
          </Box>
          <FormControl
            sx={{
              width: { xs: "80%", sm: "50%", md: "40%", lg: "20%", xl: "20%" },
            }}
          >
            <InputLabel>Tag</InputLabel>
            <Select
              multiple
              value={selectedTags}
              onChange={handleChange}
              input={<OutlinedInput label="Chip" />}
              renderValue={(selected) => (
                <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
                  {selected.map((value) => (
                    <Chip key={value} label={value} />
                  ))}
                </Box>
              )}
            >
              {tagsLoading ? (
                <MenuItem key="..." value="...">
                  ...
                </MenuItem>
              ) : (
                tags!.map((tag) => (
                  <MenuItem key={tag.id} value={tag.name}>
                    {tag.name}
                  </MenuItem>
                ))
              )}
            </Select>
          </FormControl>
          <Button
            disabled={titles.length === 0}
            variant="contained"
            onClick={onSpinWheel}
            sx={{ marginTop: 1 }}
          >
            Dreh das Rad!
          </Button>
        </>
      )}
    </Box>
  );
};

export default StartWheel;
