export interface HeadCell {
  id: string;
  label: string;
  numeric: boolean;
  sortable: boolean;
}

export const desktopHeadCells: HeadCell[] = [
  {
    id: "firstName",
    numeric: false,
    label: "Vorname",
    sortable: true,
  },
  {
    id: "lastName",
    numeric: false,
    label: "Nachname",
    sortable: true,
  },
  {
    id: "episodeCount",
    numeric: true,
    label: "Anzahl Folgen",
    sortable: true,
  },
  {
    id: "firstEpisode",
    numeric: false,
    label: "Erste Folge",
    sortable: false,
  },
  {
    id: "lastEpisode",
    numeric: false,
    label: "Letzte Folge",
    sortable: false,
  },
];

export const mobileHeadCells: HeadCell[] = [
  {
    id: "fullName",
    numeric: false,
    label: "Name",
    sortable: false,
  },
  {
    id: "episodeCount",
    numeric: true,
    label: "Anzahl Folgen",
    sortable: true,
  },
];
