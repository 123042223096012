import { useEffect, useRef, useState } from "react";
import { TitleResponse } from "../../types/TitleResponse";
import ChosenEpisode from "./ChosenEpisode";
import { Box, Slide, Typography } from "@mui/material";

export interface WheelOfFortuneProps {
  titles: TitleResponse[];
  onRestart(): void;
}

const WheelOfFortune = (props: WheelOfFortuneProps) => {
  const { titles, onRestart } = props;
  const [currentTitle, setCurrentTitle] = useState<TitleResponse>(
    {} as TitleResponse
  );
  const [speedMS, setSpeedMS] = useState(50);
  const [stopped, setStopped] = useState(false);
  const containerRef = useRef<HTMLElement>(null);

  useEffect(() => {
    const randomTitleIntervalId = setInterval(() => {
      const randomIndex = Math.floor(Math.random() * titles.length);
      setCurrentTitle(titles[randomIndex]);
    }, speedMS);

    const speedDecreaseIntervalId = setInterval(() => {
      const newSpeed = speedMS * 1.25;
      if (newSpeed > 1000) {
        clearInterval(speedDecreaseIntervalId);
        clearInterval(randomTitleIntervalId);
        setStopped(true);
      } else {
        setSpeedMS(newSpeed);
      }
    }, 500);

    return () => {
      clearInterval(randomTitleIntervalId);
      clearInterval(speedDecreaseIntervalId);
    };
  }, [titles, speedMS]);

  return (
    <>
      {stopped ? (
        <Box
          display="flex"
          alignItems="center"
          width="100%"
          height="100%"
          ref={containerRef}
        >
          <Slide
            direction="up"
            in={true}
            mountOnEnter
            unmountOnExit
            container={containerRef.current}
          >
            <ChosenEpisode title={currentTitle.title} onRestart={onRestart} />
          </Slide>
        </Box>
      ) : (
        <Box
          display="flex"
          flexDirection="column"
          alignItems="center"
          justifyContent="space-evenly"
          width="30%"
          height="100%"
          rowGap={3}
        >
          <Box flex={1}>
            <Typography fontSize={30}>Die Telefonlawine rollt...</Typography>
          </Box>
          <Box flex={1}>
            <Typography fontSize={25}>{currentTitle?.text ?? ""}</Typography>
          </Box>
        </Box>
      )}
    </>
  );
};

export default WheelOfFortune;
