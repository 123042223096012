import {
  Box,
  Button,
  Card,
  CardContent,
  Grid,
  Pagination,
  Skeleton,
  TablePagination,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import EpisodeCard from "./EpisodeCard";
import { Outlet } from "react-router-dom";
import { useEpisode } from "../../contexts/EpisodeContext";
import TuneIcon from "@mui/icons-material/Tune";
import FilterAndSort from "./FilterAndSort";
import { useState } from "react";
import FilterDialog from "./FilterDialog";
import EpisodeCardSkeleton from "./EpisodeCardSkeleton";

const Dashboard = () => {
  const theme = useTheme();
  const { episodes, pageSize, isLoading, setPage, setPageSize } = useEpisode();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  const [filterDialogOpen, setFilterDialogOpen] = useState(false);

  const handleCloseDialog = () => {
    setFilterDialogOpen(false);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setPageSize(parseInt(event.target.value, 10));
  };

  return (
    <Box margin={2}>
      <Typography fontSize={32}>Folgen</Typography>
      <Box display="flex" flexDirection="column" rowGap={1}>
        {isMobile ? (
          <Box>
            <Button
              variant="text"
              startIcon={<TuneIcon />}
              onClick={() => setFilterDialogOpen(true)}
            >
              Filter & Sortieren
            </Button>
            <FilterDialog open={filterDialogOpen} onClose={handleCloseDialog} />
          </Box>
        ) : (
          <FilterAndSort />
        )}
        {isLoading || episodes.episodes === undefined ? (
          <Box>
            <Grid container spacing={2}>
              <Grid container item spacing={3}>
                {[...Array(pageSize)].map((e, i) => (
                  <Grid item xs={12} sm={6} md={4} lg={2.4} xl={2} key={i}>
                    <EpisodeCardSkeleton />
                  </Grid>
                ))}
              </Grid>
            </Grid>
          </Box>
        ) : episodes.episodes.length === 0 ? (
          <Box
            marginTop={3}
            display="flex"
            flexDirection="column"
            justifyContent="center"
            alignItems="center"
          >
            <Typography fontSize={30}>Spur ins Nichts!</Typography>
            <Box height="2em" />
            <Typography fontSize={20}>
              Wir konnten leider keine Folgen finden die zu deiner Suche gepasst
              haben
            </Typography>
          </Box>
        ) : (
          <Box>
            <Grid container spacing={2}>
              <Grid container item spacing={3}>
                {episodes.episodes.map((episode) => {
                  return (
                    <Grid
                      item
                      xs={12}
                      sm={6}
                      md={4}
                      lg={2.4}
                      xl={2}
                      key={episode.id}
                    >
                      <EpisodeCard episode={episode} />
                    </Grid>
                  );
                })}
              </Grid>
            </Grid>
            <Box
              display="flex"
              justifyContent="center"
              marginTop="1em"
              width="100%"
            >
              <Pagination
                sx={{ display: { xs: "block", md: "none" } }}
                count={episodes.paginationMetadata.pageCount}
                page={episodes.paginationMetadata.page + 1}
                onChange={(_, newPage) => setPage(newPage - 1)}
                size="small"
                showFirstButton
                showLastButton
              />
            </Box>
            <TablePagination
              sx={{ display: { xs: "none", md: "block" } }}
              component="div"
              labelRowsPerPage="Folgen pro Seite"
              rowsPerPageOptions={[10, 30, 50]}
              count={episodes.paginationMetadata.count}
              page={episodes.paginationMetadata.page}
              rowsPerPage={pageSize}
              onPageChange={(_, newPage) => setPage(newPage)}
              onRowsPerPageChange={handleChangeRowsPerPage}
              showFirstButton
              showLastButton
            />
          </Box>
        )}
        <Outlet />
      </Box>
    </Box>
  );
};

export default Dashboard;
